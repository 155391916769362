/*
Script by : Clem
-------------------------------------------
||            Slider selector            ||
-------------------------------------------

xxxxxxxx
Rien à modifier dans ce fichier.
*/



$("document").ready(function(){

    if($(".full_site.contact, .full_site.contact-tarifs").length > 0) {

        // console.log('slider selector loaded !');

        // Initialiser les sliders si les input hidden sont déjà remplis (par ex validation du form non passée, messages *Champs obligatoire affichés)
        initial_surface = $('input#surface').val();
        initial_surface = initial_surface.replace(/\s/g, '');
        initial_surface = parseInt(initial_surface);

        if(!isNaN(initial_surface)) {
            min = 80;
            max = 250;
            $(".bulle_surface span").html(initial_surface);
            console.log('initial_surface is a number : '+initial_surface);
            position = (1 - ((max - initial_surface) / (max - min))) * 100;
            css_position = position.toFixed(2)+'%';
            setTimeout(function(){
                console.log('css_position : '+css_position);
                $('.slider_surface .ui-slider-handle').css({ left: css_position});
            }, 50); 
        } else {
            console.log('initial_surface isnt a number : '+initial_surface);
        }

        setTimeout(function(){
            console.log('slider init');
            $(".slider_surface")
            .slider({
                min: 80,
                max: 250,
                step: 10
            })
            .slider("pips", {
                rest: "label",
                step: 3
            })
            .on("slidechange", function( e, ui ) {
                $(".hidden_surface").val(ui.value);
                $(".bulle_surface span").html(ui.value);
                console.log(ui.value);
            });
        }, 1000);
    }

});
