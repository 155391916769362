$(function () {

    if ($('.contact-tarifs').length) {
        source = user_agent['source'];
        $('input[name=debug_source]').val(source);
        browser = user_agent['browser']['name'];
        $('input[name=debug_browser]').val(browser);
        device = user_agent['device']['type']+' - '+user_agent['device']['family'];
        $('input[name=debug_device]').val(device);
        os = user_agent['os']['name']+' - '+user_agent['os']['family'];
        $('input[name=debug_os]').val(os);
    }

});