/*
Script by : Clem
--------------------------------------------
||           Google maps loader           ||
--------------------------------------------

xxxxxxxx
Rien à modifier dans ce fichier.
*/


if (document.getElementById('carte_googlemaps')) { 

  function initialisation(){
    var optionsCarte = {
      zoom: 13,
      center: new google.maps.LatLng(46.651243, 5.537372),
      'scrollwheel': false,
      zoomControl: true,
      zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP
      },
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false
    }
    var maCarte = new google.maps.Map(document.getElementById("carte_googlemaps"), optionsCarte);

    var optionsMarqueur = {
          position: maCarte.getCenter(),
          map: maCarte
      };
      var marqueur = new google.maps.Marker(optionsMarqueur);
  }
  //google.maps.event.addDomListener(window, 'load', initialisation);
  window.addEventListener('load', initialisation);

};
// Fin affichage google maps