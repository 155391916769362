
$(document).ready(function () {
    //console.log('hide_unloaded_images.js loaded');
    var imgs = document.getElementsByTagName('img');
    for(var i=0,j=imgs.length;i<j;i++){
        if(!imgs[i].src) {
            //console.log('empty image found');
            imgs[i].style.display = "none";
        }
    }
});