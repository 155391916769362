$(function () {

    if ($('.contact-tarifs').length) {

        const domain = new URL(window.location.href);
        //console.log(domain.origin);

        //var tarifs_url = 'https://www.roch.fr/assets/fichiers/tarif.csv';
        var tarifs_url = domain.origin+'/assets/fichiers/tarif.csv';
        //var tarifs_url = 'http://roch.local/assets/fichiers/tarif.csv';
        var tarifs_array = [];

        $("#dialog_zone").dialog({
            autoOpen: false,
            modal: true,
            appendTo: '#contenu_page .droite form',
            position: { my: "left top", at: "left bottom", of: '#zone_recherche_cp' },
            close: function (event, ui) {
                // $('#zone_recherche_ville').val('');
                // $('#zone_recherche_cp').val('');
                // $("input[name='zone_recherche']").val('');
                // $('#tarif').val('Hors zone');
            }
        });

        $.ajax({
            type: "GET",
            url: tarifs_url,
            dataType: "text",
            success: function (data) {
                tarifs_array = $.csv.toObjects(data);
                // console.table(tarifs_array);

                // Remplir les hiddens de zone si les champs code postal et ville sont déjà remplis au chargement de la page
                code_postal = $('#zone_recherche_cp').val();
                ville = $('#zone_recherche_ville').val();
                if(code_postal != '') {
                    const regex_jura = "39([0-9]{3})";
                    var is_jura = code_postal.match(regex_jura);

                    if (is_jura) {
                        tarif_zone = 1;
                    } else {

                        let tarif_obj = tarifs_array.find(o => o.CP === code_postal);
                        if (typeof tarif_obj === 'undefined') {
                            tarif_zone = 'Hors zone';
                        } else {
                            tarif_zone = tarif_obj.Tarif;
                        } 
                    }

                    zone_recherche = code_postal+' - '+ville;

                    console.log('tarif_zone : '+tarif_zone);
                    console.log('zone_recherche : '+zone_recherche);
                    $('#tarif').val(tarif_zone);
                    $("input[name='zone_recherche']").val(zone_recherche);
                }
            }
        });

        $("#zone_recherche_cp").autocomplete({
            source: function (request, response) {
                
                // Display loader while searching
                $('.cp_search_container .loader').show();

                $.ajax({
                    url: "https://api-adresse.data.gouv.fr/search/?q=null&postcode=" + $("input[name='zone_recherche_cp']").val() + "&limit=15",
                    data: { q: request.term },
                    dataType: "json",
                    success: function (data) {
                        $('.cp_search_container .loader').hide(); // Hide loader on success
                        var postcodes = [];
                        response($.map(data.features, function (item) {
                            if ($.inArray(item.properties.postcode + " - " + item.properties.city, postcodes) == -1) {
                                postcodes.push(item.properties.postcode + " - " + item.properties.city);

                                // Tarif 1 pour toutes les communes du Jura
                                const regex_jura = "39([0-9]{3})";
                                var is_jura = item.properties.postcode.match(regex_jura);

                                if (is_jura) {
                                    tarif_zone = 1;
                                }
                                else {
                                    // trouver le tarif
                                    let tarif_obj = tarifs_array.find(o => o.CP === item.properties.postcode);

                                    if (typeof tarif_obj === 'undefined') {
                                        tarif_zone = 'Hors zone';
                                    }
                                    else tarif_zone = tarif_obj.Tarif;
                                }

                                return {
                                    label: item.properties.postcode + " - " + item.properties.city,
                                    city: item.properties.city,
                                    tarif: tarif_zone,
                                    value: item.properties.postcode
                                };
                            }
                        }));
                    }
                });
            },
            // On remplit aussi la ville
            select: function (event, ui) {
                $('#zone_recherche_ville').val(ui.item.city);
                $('#tarif').val(ui.item.tarif);
                $("input[name='zone_recherche']").val(ui.item.label);
                console.log('Zone tarif ' + ui.item.tarif);
                if (ui.item.tarif === 'Hors zone') {
                    $('#dialog_zone').dialog('open');
                }
            }
        });

        $('#zone_recherche_cp').on('focusout', function () {
            var cp = $('#zone_recherche_cp').val();
            var is_cp = new RegExp(/^(([0-8][0-9])|(9[0-5]))[0-9]{3}$/, 'g');
            if (!is_cp.test(cp)) {
                console.log('CP incorrecte');
                $('#zone_recherche_ville').val('');
                $('#zone_recherche_cp').val('');
                $("input[name='zone_recherche']").val('');
                $('#tarif').val('Hors zone');
                $('.cp_search_container .loader').hide(); // Hide loader
            } else if($('#zone_recherche_ville').val() == '') {
                console.log('Champs ville non rempli');
                $('#zone_recherche_ville').val('');
                $('#zone_recherche_cp').val('');
                $("input[name='zone_recherche']").val('');
                $('#tarif').val('Hors zone');
                $('.cp_search_container .loader').hide(); // Hide loader
            } else {
                // Tarif 1 pour toutes les communes du Jura
                const regex_jura = "39([0-9]{3})";
                var is_jura = cp.match(regex_jura);

                if (is_jura) {
                    tarif_zone = 1;
                }
                else {
                    // trouver le tarif
                    let tarif_obj = tarifs_array.find(o => o.CP === cp);

                    if (typeof tarif_obj === 'undefined') {
                        tarif_zone = 'Hors zone';
                    }
                    else tarif_zone = tarif_obj.Tarif;
                 
                }

                $('#tarif').val(tarif_zone);
                console.log('Zone tarif ' + tarif_zone);
                if (tarif_zone === 'Hors zone') {
                    $('#dialog_zone').dialog('open');
                }
            }
        });

        // Prevent default "Enter" form validation, click on button instead
        var input = document.getElementById("zone_recherche_cp");
        input.addEventListener("keyup", function(event) {
            console.log('Key pressed');
            if (event.keyCode === 13) { // Number 13 is the "Enter" key on the keyboard
                console.log('Key is Enter');
                event.preventDefault();
                var cp = $('#zone_recherche_cp').val();
                var is_cp = new RegExp(/^(([0-8][0-9])|(9[0-5]))[0-9]{3}$/, 'g');
                if (!is_cp.test(cp)) {
                    console.log('CP incorrecte');
                    $('#zone_recherche_ville').val('');
                    $('#zone_recherche_cp').val('');
                    $("input[name='zone_recherche']").val('');
                    $('#tarif').val('Hors zone');
                    $('.cp_search_container .loader').hide(); // Hide loader
                } else if($('#zone_recherche_ville').val() == '') {
                    console.log('Champs ville non rempli');
                    $('#zone_recherche_ville').val('');
                    $('#zone_recherche_cp').val('');
                    $("input[name='zone_recherche']").val('');
                    $('#tarif').val('Hors zone');
                    $('.cp_search_container .loader').hide(); // Hide loader
                }
                $('.form-input-submit input').click();
            }
        });

        

        // $("#zone_recherche_ville").autocomplete({
        //     source: function (request, response) {
        //         $.ajax({
        //             url: "https://api-adresse.data.gouv.fr/search/?q=" + $("input[name='zone_recherche_ville']").val() + "&type=city&limit=15",
        //             data: { q: request.term },
        //             dataType: "json",
        //             success: function (data) {
        //                 var cities = [];
        //                 response($.map(data.features, function (item) {
        //                     if ($.inArray(item.properties.city, cities) == -1) {
        //                         cities.push(item.properties.city);

        //                         // Tarif 1 pour toutes les communes du Jura
        //                         const regex_jura = "39([0-9]{3})";
        //                         var is_jura = item.properties.postcode.match(regex_jura);

        //                         if (is_jura)
        //                             tarif_zone = 1;
        //                         else {
        //                             let tarif_obj = tarifs_array.find(o => o.CP === item.properties.postcode);

        //                             if (typeof tarif_obj === 'undefined' && !is_jura){
        //                                 tarif_zone = 'Hors zone';
        //                             }
        //                             else tarif_zone = tarif_obj.Tarif;   

        //                             return {
        //                                 label: item.properties.postcode + " - " + item.properties.city,
        //                                 postcode: item.properties.postcode,
        //                                 tarif: tarif_zone,
        //                                 value: item.properties.city
        //                             };
        //                         }
        //                     }
        //                 }));
        //             }
        //         });
        //     },
        //     // On remplit aussi le CP
        //     select: function (event, ui) {
        //         $('#zone_recherche_cp').val(ui.item.postcode);
        //         $('#tarif').val(ui.item.tarif);
        //         console.log('Zone tarif '+ui.item.tarif);
        //         if( ui.item.tarif === 'Hors zone' ){
        //             $('#dialog_zone').dialog('open');
        //         }
        //     }
        // });

    }

});