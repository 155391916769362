
$(document).ready(function() {
	if($('#historique').length) {
		var zone_height = 0;
		var full_height = $('#historique .zone .row').outerHeight();

		// Get block height
		function getBlockHeight(params) {
			full_height = $('#historique .zone .row').outerHeight();
			var bloc_height = $('.bloc_historique:first-child').innerHeight();
			var bottomMargin = parseInt($('.bloc_historique:first-child').css('margin-bottom').replace('px', ''));
			bloc_height += bottomMargin;
			
			window_width = $(window).width();
			if(window_width > 991) {
				zone_height = bloc_height * 2;
			} else {
				zone_height = bloc_height * 4;
			}
			$('#historique .zone').css('transition', '0s');
			$('#historique .zone').css('maxHeight', zone_height);
			setTimeout(() => {
				$('#historique .zone').css('transition', '0.5s');
			}, 500);
		}
		setTimeout(() => {
			getBlockHeight();
		}, 300);
		

		$(window).resize(function () { 
			getBlockHeight();
		});

		// Manage click on "more"
		$('.more_container .plus_container').click(function () { 
			$('#historique .zone').css('maxHeight', full_height);
		});
	}
});