
$(document).ready(function() {
	if($('#liste_realisations').length) {
		zone_height = 0;

		// Get block height
		function getBlockHeight(params) {
			var bloc_height = $('.realisation_item').outerHeight();
			var bottomMargin = parseInt($('.realisation_item').css('margin-bottom').replace('px', ''));
			bloc_height += bottomMargin;
			window_width = $(window).width();
			if(window_width > 991) {
				zone_height = bloc_height * 2;
			} else {
				zone_height = bloc_height * 4;
			}
			var current_step = $('.more_container').data('step');
			var new_zone_size = current_step * zone_height;
			$('#liste_realisations .zone').css('transition', '0s');
			$('#liste_realisations .zone').css('maxHeight', new_zone_size);
			setTimeout(() => {
				$('#liste_realisations .zone').css('transition', '0.5s');
			}, 500);
		}
		getBlockHeight();

		$(window).resize(function () { 
			getBlockHeight();
		});

		// Set initial "more bar" state
		var nb_blocs = $(".realisation_item").length;
		$('.more_container span.total').html(nb_blocs);
		var current_blocs = 4 * $('.more_container').data('step');
		if(nb_blocs > current_blocs) {
			$('.more_container span.current').html(current_blocs);
		} else {
			$('.more_container span.current').html(nb_blocs);
		}

		// Manage click on "more"
		$('.more_container .plus_container, .more_container .plus_container_txt').click(function () { 
			var current_step = $('.more_container').data('step');
			var next_step = current_step + 1;
			var new_zone_size = next_step * zone_height;
			$('.more_container').data('step', next_step);
			$('.more_container').attr('data-step', next_step);

			$('#liste_realisations .zone').css('maxHeight', new_zone_size);
			if(next_step * 4 > nb_blocs) {
				$('.more_container span.current').html(nb_blocs);
			} else {
				$('.more_container span.current').html(next_step * 4);
			}
		});
	}
});