/*
Script by : Clem
-------------------------------------------
||          Goto carousel slide          ||
-------------------------------------------

xxxxxxxx
Rien à modifier dans ce fichier.
*/


$('.goto-carousel-slide').on('click', function(e) {

	var target_modal = $(this).attr('data-target');
	var goto_slide = parseInt($(this).attr('data-goto'));

	$(target_modal).find('#carousel_modal').carousel(goto_slide-1);

});