
$(document).ready(function(){
	// Cacher les images qui n'ont pas de source dans les galeries
    //$('.demi_container img[src=""]').hide();
    $('img[src=""]').hide();
    $('img[src=""]').addClass('hidden');

    // Supprimer la slide de trop dans le carousel d'actualités
    $('#carousel_news .carousel-item:last-child').remove();


    // Modifier Breadcrumbs pour ajouter les microdatas
    $('#breadcrumb').attr('itemscope', 1);
    $('#breadcrumb').attr('itemtype', 'http://schema.org/ListItem');
    $('#breadcrumb li').each(function(index) {
		$(this).attr('itemprop', 'itemListElement');
		$(this).attr('itemscope', 1);
		$(this).attr('itemtype', 'http://schema.org/ListItem');
		$(this).find('a').attr('itemprop', 'item');
		base_html = $(this).find('a').html();
		new_html = '<span itemprop="name">'+base_html+'</span>';
		$(this).find('a').html(new_html);
	});

    
    // Si demande de doc papier
    $('.checkbox_doc_papier').change(function() {
	    if(this.checked) {
	    	$('#adresse').prop('required',true);
	    	$('#code_postal').prop('required',true);
	    } else {
	    	$('#adresse').removeAttr('required');
			$('#code_postal').removeAttr('required');
	    }
	}); 
    
});