
$(document).ready(function() {
	if (undefined == $.cookie('cookies_acceptance'))
	$('#cookie_notice').removeClass('hide');
});


$('#filtres .btn').click(function (e) { 
	if($(this).hasClass('active')) {
		$(this).removeClass('active');
	} else {
		$(this).addClass('active')
	}

	if($('.btn.actualites').hasClass('active')) {
		$('.news_item.actualite').show();
	} else {
		$('.news_item.actualite').hide();
	}
	if($('.btn.revue').hasClass('active')) {
		$('.news_item.revue').show();
	} else {
		$('.news_item.revue').hide();
	}
	if($('.btn.conseils').hasClass('active')) {
		$('.news_item.conseils').show();
	} else {
		$('.news_item.conseils').hide();
	}
	if($('.btn.club').hasClass('active')) {
		$('.news_item.club').show();
	} else {
		$('.news_item.club').hide();
	}
});