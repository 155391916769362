/*
Script by : Clem
-----------------------------------------
||           Bootstrap nav++           ||
-----------------------------------------

xxxxxxxx
Rien à modifier dans ce fichier.
*/

// Ouverture des dropdowns
if(device_is_desktop) {
	$('.nav-item.dropdown').mouseenter(
		function() {
			if($(this).hasClass('show')) {
				console.log('Déjà affiché');
			} else {
				$(this).find('[data-toggle=dropdown]').dropdown('toggle');
			}
		}
	);

	$('.nav-item.dropdown').mouseleave(
		function() {
			if($(this).hasClass('show')) {
				$(this).find('[data-toggle=dropdown]').dropdown('toggle');
			} else {
				console.log('Déjà fermé');
			}
		}
	);
}
